<template>
    <div class="container">
        <div class="row justify-content-center mt-4">

            <div class="col-md-12 mt-4" v-if="certInfos.length">
                <div v-for="(item, index) in certInfos" :key="index">
                  <p>Шаблон сертификата : {{ item.certTemplate }}</p>

                  <div v-if="item.bin == ''">
                    <p>ИИН: {{ item.serialNumber }}</p>
                    <p>ФИО: {{ item.commonName }}&nbsp;{{ item.givenName }}</p>
                  </div>
                  <div v-else>
                    <p>БИН: {{ item.bin }}</p>
                    <p>Название организации : {{ item.orgName }}</p>
                    <p>Роль подписанта : {{ item.roleName }}</p>
                  </div>

                  <p>Серийный номер сертификата: {{ item.certificateSerialNumber }}</p>

                  <p>Срок действия сертификата: {{ item.notBefore }} - {{ item.notAfter }}</p>

                  <p>Дата подписания : {{ item.time }}</p>
                  <hr>
                </div>

            </div>
            <div v-else class="text-center">
                <h4>Документ не подписан</h4>
            </div>

        </div>
    </div>

</template>

<script>

    import axios from "axios";

    export default {
        name: "SignatureInfo",

        data() {
            return {
                fileId: +this.$route.query.id || 0,
                infoText: '',
                certInfos: []
            }
        },
        methods: {
            async GET_INFO_CERT(fileId) {
                try {
                    const {
                        status,
                        data
                    } = await axios.get('https://signature.uib.kz/infoCert.php?file_id=' + fileId);
                    if (status === 200) {

                        console.log(data, "INFOCERT")
                        if (data == '0 results[]') {
                            return []
                        }
                        return data
                    }
                } catch (e) {
                    console.error(e.response, 'Error info cert');
                    return []
                }
            }
        },
        async created() {
            if (this.fileId) {
                this.certInfos = await this.GET_INFO_CERT(this.fileId)
            }
        },
    }
</script>

<style scoped>

</style>